<template>
    <div class="custom-page">
        <MainHeader />
        
        <div class="text-wrapper">
            <p class="section-title red">
                CANAL DE DENUNCIAS de  GGTech Entertainment S.L.
            </p>
            <p>
                El Art. 31.bis. del Código Penal habla sobre la responsabilidad de la persona jurídica, en él, no sólo se determinan las personas penalmente responsables, sino que en su punto 5, se establecen los requisitos que deben cumplir los modelos de gestión de prevención de delitos a implantar en las organizaciones. Uno de esos requisitos, es la existencia de un medio para informar de posibles incumplimientos:
            </p>
            <p>
                <em>
                    “Impondrán la obligación de informar de posibles riesgos e incumplimientos al organismo encargado de vigilar el funcionamiento y observancia del modelo de prevención.”
                </em>
            </p>
            <p>
                Por otro lado, y atendiendo con lo establecido en la Ley 2/2023, de 20 de febrero, reguladora de la protección de las personas que informen sobre infracciones normativas y de lucha contra la corrupción, concretamente en su artículo 10 (sector privado) y su artículo 13 (sector público), se impone la obligación de establecer canales de denuncia internos.
            </p>
            
            <p class="section-subtitle red">
                GGTech Entertainment S.L., informa:
            </p>
            
            <p>
                <strong>CANAL DE DENUNCIAS.</strong> El presente canal de denuncias garantiza el cumplimiento de lo establecido por la doctrina derivada de la Circular de la Fiscalía 1/2016, de 22 de enero y por lo recogido en la Ley 2/2023 anteriormente mencionada.
            </p>
            
            <p>
                Así mismo, el canal de denuncias externo establecido es administrado por un prestador cualificado de servicios de confianza que cumple con las especificaciones previstas en El Reglamento (UE) nº 910/2014 del Parlamento Europeo y del Consejo de 23 de julio de 2014 relativo a la identificación electrónica y los servicios de confianza para las transacciones electrónicas en el mercado interior y la Ley 6/2020, de 11 de noviembre, reguladora de determinados aspectos de los servicios electrónicos de confianza.
            </p>
            
            
            <P>
                El objetivo de utilizar un prestador cualificado de servicios de confianza es garantizar la presunción de veracidad y autenticidad de la prueba, estableciendo la carga de la prueba en aquel que impugne el documento, conforme a lo previsto en el Art. 326 de Ley 1/2000, de 7 de enero, de enjuiciamiento civil. 
                
            </P>
            
            <p>
                En todo caso, el prestador cualificado de servicios de confianza cumplirá con las instrucciones establecidas por el GGTech Entertainment S.L. y siempre conforme a la legislación vigente en materia de protección de datos, garantizándose el cumplimiento de las obligaciones previstas en el Art. 28 del RGPD.
                
            </p>
            
            <p>
                <strong>DENUNCIAS ANÓNIMAS Y DATOS PERSONALES.</strong> Las denuncias serán anónimas con carácter general y se contestarán por el mismo canal por el que se han recibido. El anonimato se levantará sólo con el consentimiento expreso del informante o cuando constituya una obligación necesaria y proporcionada impuesta por el Derecho de la Unión o nacional en el contexto de una investigación llevada a cabo por las autoridades nacionales o en el marco de un proceso judicial, en particular para salvaguardar el derecho de defensa de la persona afectada.
            </p>
            
            <p>
                Durante el proceso se garantizará, por tanto, el cumplimiento de la legislación en materia de protección de datos vigente. (LOPD y RGPD).
                
            </p>
            
            <p>
                <strong>USO DEL CANAL DE DENUNCIAS.</strong> Cuando vaya a establecer una denuncia, observará que es dirigido a una herramienta on-line externa al dominio del GGTech Entertainment S.L. el mensaje será transmitido a la herramienta del prestador cualificado de confianza y así poder garantizar el anonimato y la protección de los datos del denunciante.
                
            </p>
            <p>
                Por ello, la presente herramienta podrá ser utilizada por cualquier empleado del GGTech Entertainment S.L. o bien cualquier otra tercera persona que pueda tener conocimiento de conductas contrarias a la ética, fraudulentas o ilícitas cometidas en el seno de nuestra Organización.
                
            </p>
            <p>
                Este canal de denuncias no es el canal idóneo para temas relacionados con sus condiciones de empleo o cuestiones disciplinarias. En ese caso deberá seguir las políticas establecidas en su organización.
            </p>
            
            <p>
                <a class="btn block primary" target="_blank" href="https://www.coloriuris.net/canal-denuncias/formulario/GGtech
                ">canal ético</a>
            </p>
            
            <p>
                <small>
                    El responsable del tratamiento de los datos es el GGTech Entertainment S.L. que, en cumplimiento de una obligación legal prevista en el la Ley 2/2023, de 21 de febrero, reguladora de la protección de las personas que informen sobre infracciones normativas y de lucha contra la corrupción, tratará la información con la finalidad de gestionar las denuncias que se reciban a través del canal, garantizando la confidencialidad de los datos del denunciante manteniéndolos anónimos sin comunicarlos a terceros salvo que su identificación constituya una obligación necesaria y proporcionada impuesta por el derecho de la UE o nacional en el contexto de una investigación llevada a cabo por las autoridades nacionales o en el marco de un proceso judicial, en cuyo caso deberá comunicarse a las autoridades competentes en el asunto.
                </small>
            </p>
            
            <p>
                <small>
                    Sus datos serán conservados durante un período máximo de 3 meses desde la introducción de los datos en el canal, transcurrido el cual se procederá a su eliminación del canal, pero podrán permanecer bloqueados cuando sea necesario para evidenciar el funcionamiento del modelo de prevención de delitos o pueda ser requerido por la autoridad competente para el inicio de la correspondiente investigación de los hechos.
                </small>
            </p>
            
            <p>
                <small>
                    Para más información sobre el tratamiento de sus datos o como ejercitar sus derechos puede dirigirse a nuestra <a href="https://s3.eu-west-1.amazonaws.com/2023.ggtech.gg/privacy/aviso-legal-dodit.pdf" target="_blank">Política de Privacidad</a>.
                </small>
            </p>
        </div>
        <MainFooter />

    </div>
</template>


<script>
import MainFooter from '../components/MainFooter.vue';
import MainHeader from '../components/MainHeader.vue'

export default {
    // name: 'App',
    components: {
    MainHeader,
    MainFooter
},
}
</script>